<div class="action-current">
  <button mat-button color="primary" (click)="setCurrent()">{{ i18n.get('currentState') }}</button>
</div>

@if (snapshots$ | async; as snapshots) {
  <table mat-table [dataSource]="snapshots.data" class="mat-elevation-z8">
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>{{ i18n.get('snapshotTimestamp') }}</th>
      <td mat-cell *matCellDef="let element" (click)="setHistory(element)" aria-role="button">
        <button mat-button class="history-element">
          {{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['createdAt']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['createdAt']"></tr>
    <tr class="mat-mdc-row" *matNoDataRow>
      <td class="mat-mdc-cell mdc-data-table__cell">{{ i18n.get('noSnapshots') }}</td>
    </tr>
  </table>
}
<mat-paginator [length]="resultSize" [pageSize]="20" aria-label="Select page of Snapshot search result"></mat-paginator>
