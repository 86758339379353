<div mat-dialog-title>{{ i18n.get('blobMetaTitle') }}</div>
@if (noOfflineText) {
  <div mat-dialog-content>
    <div class="layerLabel">{{ label }}</div>
    <div class="noOfflineText">{{ noOfflineText }}</div>
  </div>
} @else {
  <div mat-dialog-content>
    <div class="layerLabel">{{ label }}</div>
    <div class="sectionTitle">{{ i18n.get('blobMetaDataTitle') }}</div>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('blobMetaDataDefaultUrl') }}</mat-label>
      <input matInput type="text" [(ngModel)]="dataUrlDefault" disabled />
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('blobMetaDataUrl') }}</mat-label>
      <input matInput type="text" [(ngModel)]="dataUrl" />
    </mat-form-field>
    <div class="buttons">
      @switch (dataBlobMeta?.blobState) {
        @case ('loading') {
          <button mat-stroked-button color="warn" class="button-cancel" (click)="cancelDownloadData()">{{ i18n.get('cancel') }}</button>
          <mat-progress-bar color="primary" class="map-progress" mode="determinate" [value]="dataProgress" />
        }
        @case ('downloaded') {
          <button mat-stroked-button color="warn" class="button-remove" (click)="removeData()">
            
            {{ i18n.get('removeSymbol') }}
          </button>
          <div class="source">{{ i18n.get('blobMetaBlobSource') }} {{ dataBlobMeta?.source }}</div>
        }
        @default {
          <button mat-stroked-button class="button-download" (click)="downloadData()">
            <mat-icon>file_download</mat-icon>{{ i18n.get('download') }}
          </button>
          <button mat-stroked-button class="button-upload" (click)="dataUpload.click()">
            <mat-icon>file_upload</mat-icon>{{ i18n.get('upload') }}
          </button>
          <input type="file" class="mapfile-input" [accept]="mapUploadType" (change)="uploadData($event)" #dataUpload />
        }
      }
    </div>
    <div class="sectionTitle">{{ i18n.get('blobMetaStyleTitle') }}</div>
    @if (layerConfigStyle) {
      <div>{{ i18n.get('blobMetaStyleLayerSetting') }}</div>
    } @else {
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('blobMetaStyleDefaultUrl') }}</mat-label>
        <input matInput type="text" [(ngModel)]="styleUrlDefault" disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('blobMetaStyleUrl') }}</mat-label>
        <input matInput type="text" [(ngModel)]="styleUrl" />
      </mat-form-field>
      <div class="buttons">
        @switch (styleBlobMeta?.blobState) {
          @case ('loading') {
            <button mat-stroked-button color="warn" class="button-cancel" (click)="cancelDownloadStyle()">{{ i18n.get('cancel') }}</button>
            <mat-progress-bar color="primary" class="map-progress" mode="determinate" [value]="styleProgress" />
          }
          @case ('downloaded') {
            <button mat-stroked-button color="warn" class="button-remove" (click)="removeStyle()">
              <mat-icon>file_download</mat-icon>
              {{ i18n.get('removeSymbol') }}
            </button>
            @if (styleText === null) {
              <button mat-flat-button color="primary" class="button-editStyle" (click)="startEditStyle()">
                <mat-icon>edit</mat-icon>{{ i18n.get('edit') }}
              </button>
            }
            <div class="source">{{ i18n.get('blobMetaBlobSource') }} {{ styleBlobMeta?.source }}</div>
          }
          @default {
            <button mat-stroked-button class="button-download" (click)="downloadStyle()">
              <mat-icon>file_download</mat-icon>{{ i18n.get('download') }}
            </button>
            <button mat-stroked-button class="button-upload" (click)="styleUpload.click()">
              <mat-icon>file_upload</mat-icon>{{ i18n.get('upload') }}
            </button>
            <input type="file" class="mapfile-input" accept=".json" (change)="uploadStyle($event)" #styleUpload />
          }
        }
      </div>
      @if (styleText !== null) {
        <div>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>{{ i18n.get('blobMetaStyleText') }}</mat-label>
            <textarea matInput [(ngModel)]="styleText"></textarea>
          </mat-form-field>
          <div class="buttons">
            <button mat-flat-button color="primary" class="button-editStyle" (click)="endEditStyle()">{{ i18n.get('ok') }}</button>
            <button mat-flat-button class="button-editStyle" (click)="cancelEditStyle()">{{ i18n.get('cancel') }}</button>
          </div>
        </div>
      }
      @if (data.localMap) {
        <div>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>{{ i18n.get('styleSourceName') }}</mat-label>
            <input matInput type="text" [(ngModel)]="styleSourceName" />
          </mat-form-field>
        </div>
      }
    }
  </div>
}
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="ok()">
    {{ i18n.get('ok') }}
  </button>
</div>
