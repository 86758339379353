
<mat-accordion multi displayMode="flat">
  <h2>{{ i18n.get('layers') }} & {{ i18n.get('currentMap') }}</h2>
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('currentMap') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      
      @if (mapState.getActiveLayer(); as layer) {
        <div class="selectedLayer">
          
          <div>
            <div class="titleRow">
              <div class="title">{{ layer.observeName() | async }}</div>
            </div>
            <div>
              <mat-slider [max]="1" [min]="0" [step]="0.1" class="layer-slider">
                <input matSliderThumb [ngModel]="mapState.observeMapOpacity() | async" (ngModelChange)="mapState.setMapOpacity($event)" />
              </mat-slider>
            </div>
          </div>
          @for (mapSource of mapSources; track mapSource; let index = $index) {
            <div>
              <mat-radio-group>
                <mat-radio-button [value]="mapSource.key" (click)="switchMapSource(mapSource.key)" [checked]="mapSource.selected">
                  {{ mapSource.translation }}
                </mat-radio-button>
              </mat-radio-group>
              @if (workLocal && mapSource.key !== 'noBaseMap') {
                <span class="mapSourceAvailable" (click)="showLocalInfoMap(mapSource.key, index)">
                  @if (isDownloadableMap(mapSource.key)) {
                    @if (mapSource.offlineAvailable) {
                      <i class="material-icons">file_download_done</i>
                    }
                    @if (!mapSource.offlineAvailable) {
                      <i class="material-icons">file_download</i>
                    }
                  } @else {
                    <i class="material-icons">file_download_off</i>
                  }
                </span>
              }
              @if (isDownloadableMap(mapSource.key)) {
                <div class="buttons">
                  @switch (mapDownloadStates[mapSource.key]) {
                    @case ('loading') {
                      <button mat-flat-button color="warn" class="button-cancel" (click)="cancelDownloadMap(mapSource.key)">
                        {{ i18n.get('cancel') }}
                      </button>
                      <mat-progress-bar color="primary" class="map-progress" mode="determinate" [value]="mapProgress" />
                    }
                    @case ('downloaded') {
                      <button mat-flat-button color="warn" class="button-remove" (click)="removeLocalMap(mapSource.key)">
                        <mat-icon>file_download</mat-icon>
                        {{ i18n.get('removeSymbol') }}
                      </button>
                    }
                    @default {
                      <button mat-stroked-button class="button-download" (click)="downloadMap(mapSource.key)">
                        <mat-icon>file_download</mat-icon>{{ i18n.get('download') }}
                      </button>
                      <button mat-stroked-button class="button-upload" (click)="mapUpload.click()">
                        <mat-icon>file_upload</mat-icon>{{ i18n.get('upload') }}
                      </button>
                      <input type="file" class="mapfile-input" accept=".pmtiles" (change)="uploadMap($event, mapSource.key)" #mapUpload />
                    }
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </ng-template>
  </mat-expansion-panel>

  @if ((mapState.observeSelectedMapLayers$() | async)?.length ?? 0 > 0) {
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ i18n.get('layers') }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="allSelected">
          @for (
            item of mapState.observeSelectedMapLayers$() | async;
            track item;
            let index = $index;
            let first = $first;
            let last = $last
          ) {
            <div title="{{ item.label }}" class="selectedLayer">
              <div>
                <div class="titleRow">
                  <div class="title">{{ item.label }}</div>
                  @if (!first) {
                    <i (click)="mapState.sortMapLayerUp(index)" class="material-icons">arrow_upward</i>
                  }
                  @if (!last) {
                    <i (click)="mapState.sortMapLayerDown(index)" class="material-icons">arrow_downward</i>
                  }
                  @if (workLocal) {
                    <div (click)="showLocalInfo(item, index)">
                      @if (item.type === 'geojson' || item.type === 'csv') {
                        @if (item.offlineAvailable) {
                          <i class="material-icons">file_download_done</i>
                        }
                        @if (!item.offlineAvailable) {
                          <i class="material-icons">file_download</i>
                        }
                      } @else {
                        <i class="material-icons disabled">file_download_off</i>
                      }
                    </div>
                  }
                  
                  @if (mapState.isExpertView() && item.source && (item.type === 'wms' || item.type === 'wms_custom')) {
                    <i (click)="showWmsLayerOptions(item, index)" class="material-icons">settings</i>
                  }
                  @if (mapState.isExpertView() && item.source && (item.type === 'geojson' || item.type === 'csv')) {
                    <i (click)="showGeoJSONLayerOptions(item, index)" class="material-icons">settings</i>
                  }
                  @if (!item.hidden) {
                    <i (click)="mapState.toggleMapLayer(item, index)" class="material-icons">visibility_off</i>
                  } @else {
                    <i (click)="mapState.toggleMapLayer(item, index)" class="material-icons">visibility_on</i>
                  }
                  @if (item.type !== 'geojson' && item.type !== 'csv') {
                    <i (click)="showLegend(item)" class="material-icons">info_outline</i>
                  }
                  <i (click)="mapState.removeMapLayer(index)" class="material-icons">remove_circle_outline</i>
                  
                </div>
                <div>
                  <mat-slider [max]="1" [min]="0" [step]="0.1" [disabled]="item.hidden">
                    <input matSliderThumb [ngModel]="item.opacity" (ngModelChange)="mapState.setMapLayerOpacity(index, $event)" />
                  </mat-slider>
                </div>
              </div>
              
            </div>
          }
          <ng-template #newLayerTypeTemplate>
            <div mat-dialog-title>{{ i18n.get('newLayerType') }}</div>
            <div mat-dialog-content>
                <mat-radio-group [(ngModel)]="newLayerType" class="newLayerType">
                  <mat-radio-button value="wms_custom">WMS (custom)</mat-radio-button>
                  <mat-radio-button value="geojson">GeoJSON</mat-radio-button>
                  <mat-radio-button value="csv">CSV</mat-radio-button>
                </mat-radio-group>
            </div>
            <div mat-dialog-actions align="end">
              <button mat-stroked-button [mat-dialog-close]="null" [innerText]="i18n.get('cancel')"></button>
              <button mat-flat-button color="primary" [mat-dialog-close]="newLayerType" [innerText]="i18n.get('ok')"></button>
            </div>
          </ng-template>
          @if (mapState.isExpertView()) {
            <button
              mat-stroked-button
              class="expertButton"
              (click)="addNewLayer()"
              [innerText]="i18n.get('addNewLayer')"
            ></button>
          }
          @if (mapState.isExpertView()) {
            <button
              mat-stroked-button
              class="expertButton"
              (click)="persistLayers()"
              [innerText]="i18n.get('persistLayers')"
            ></button>
          }
        </div>
      </ng-template>
    </mat-expansion-panel>
  }
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('favoriteLayers') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      @if (favouriteLayers$ | async; as favouriteLayers) {
        <mat-action-list class="availableLayers">
          @if (favouriteLayers?.length === 0) {
            <span>{{ i18n.get('noMoreFavorites') }}</span>
          }
          @for (item of favouriteLayers; track item) {
            <button
              mat-list-item
              (click)="selectLayer(item)"
              title="{{ item.label }} ({{ item.source ? item.source.label ?? item.source.url : 'GeoAdmin' }}{{
                item.id ? '; ' + i18n.get('globalMapLayerTitleSuffix') : ''
              }})"
            >
              @if (isSearchable(item)) {
                <mat-icon [title]="i18n.get('searchableLayer')">search</mat-icon>
              }
              {{ item.label }}
            </button>
          }
        </mat-action-list>
      }
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('availableLayers') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="sourceControl">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{ i18n.get('layerSource') }}</mat-label>
          <mat-select [formControl]="sourceFilter">
            <mat-option value="ALL">{{ i18n.get('allSources') }}</mat-option>
            <mat-option value="_GeoAdmin_"
              >GeoAdmin
              @if (geoAdminLayerError) {
                <mat-icon class="wmsSourceError" [title]="geoAdminLayerError">warning</mat-icon>
              }
            </mat-option>
            <mat-option value="_GlobalMapLayers_">{{ i18n.get('globalMapLayers') }}</mat-option>
            @for (item of mapState.observeWmsSources$() | async; track item) {
              <mat-option [value]="item.url">
                {{ item.label }}
                @if (wmsSourceLoadErrors[item.url]) {
                  <mat-icon class="wmsSourceError" [title]="wmsSourceLoadErrors[item.url]">warning</mat-icon>
                }
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (mapState.isExpertView()) {
          <button mat-icon-button (click)="editWmsSources()">
            <mat-icon [attr.aria-label]="i18n.get('editWmsSources')">edit</mat-icon>
          </button>
        }
      </div>
      <mat-form-field appearance="outline">
        <mat-label> {{ i18n.get('filter') }}</mat-label>
        <input type="text" matInput [formControl]="layerFilter"
      /></mat-form-field>
      <mat-action-list class="availableLayers">
        @for (item of filteredAvailableLayers$ | async; track item) {
          <button
            mat-list-item
            (click)="selectLayer(item)"
            title="{{ item.label }} ({{ item.source ? item.source.label ?? item.source.url : 'GeoAdmin' }}{{
              item.id ? '; ' + i18n.get('globalMapLayerTitleSuffix') : ''
            }})"
          >
            @if (isSearchable(item)) {
              <mat-icon [title]="i18n.get('searchableLayer')">search</mat-icon>
            }
            {{ item.label }}
          </button>
        }
      </mat-action-list>
    </ng-template>
  </mat-expansion-panel>
<mat-divider></mat-divider>
@if (mapState.isExpertView()) {
<div class="buttonContent">
  <button mat-stroked-button (click)="editLayerSettings()" [innerText]="i18n.get('organisationSettings')"></button>
</div>
}
<h2>{{ i18n.get('filter') }}</h2>
<mat-divider></mat-divider>
<app-sidebar-filters></app-sidebar-filters>
</mat-accordion>
