<div #mapElement id="map" class="map"></div>
@if (renderer.observeMousePosition() | async; as mousePosition) {
  @if (renderer.observeCurrentSketchSize() | async; as sketchSize) {
    <div
      class="tooltip noprint"
      [style.left.px]="mousePosition[0]"
      [style.top.px]="mousePosition[1]"
      [innerHTML]="sketchSize"
    ></div>
  }
}

<button mat-mini-fab class="edit-button" color="warn" #deleteButton (click)="remove()">
  <i class="material-icons">delete_forever</i>
</button>
<button
  mat-mini-fab
  class="edit-button rotate-button"
  color="primary"
  #rotateButton
  (mousedown)="startRotating()"
  (touchstart)="startRotating()"
>
  <!-- todo maybe add click" -->
  <i class="material-icons">rotate_left</i>
</button>
<button mat-mini-fab class="edit-button" color="primary" #copyButton (click)="copy()">
  <i class="material-icons">content_copy</i>
</button>

<button mat-mini-fab class="edit-button" color="primary" #drawButton (click)="toggleDrawingDialog()">
  <mat-icon>stars</mat-icon>
</button>
<button mat-mini-fab class="edit-button" #closeButton (click)="hidePositionFlag()">
  <mat-icon>close</mat-icon>
</button>
