<div mat-dialog-content>
  @if (!this.html) {
    <div class="loading">
      <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
    </div>
  } @else {
    <div [innerHTML]="html"></div>
  }
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{ i18n.get('ok') }}
  </button>
</div>
