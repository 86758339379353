<h1 mat-dialog-title>{{ i18n.get('import') }}</h1>
<div mat-dialog-content class="dialogContent">
  <input id="loadFromFile" type="file" accept=".zsjson" #fileInput />
</div>
<div mat-dialog-actions align="end" class="buttons">
  <button mat-button (click)="onNoClick()">
    {{ i18n.get('cancel') }}
  </button>
  <button mat-flat-button color="primary" (click)="readFromFile()">
    {{ i18n.get('import') }}
  </button>
</div>
