<div class="coordinates mat-elevation-z3">
  @if (showOptions) {
    <app-projection-selection
      [projectionFormatIndexes]="projectionFormatIndexes()"
      [showNumerical]="false"
      [multiple]="true"
      (projectionChanged)="updateProjection($event)"
    ></app-projection-selection>
  }
  <div class="box">
    <div class="coords">
      @for (coordinate of mappedCoordinates(); track coordinate.type) {
        <span>
          {{ coordinate.coordinate }}
        </span>
      }
    </div>
    
   <button class="switch" mat-icon-button (click)="toggleOptions()"> 
    <mat-icon>{{ showOptions ? 'save' : 'settings' }}</mat-icon>
  </button>
    
  </div>
</div>
