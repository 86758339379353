<div mat-dialog-content>{{ data }}</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="true" (click)="cancel()" [innerText]="i18n.get('cancel')"></button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="ok()"
    [innerText]="i18n.get('confirm')"
  ></button>
</div>
