<div class="projectionSelection">
  @if (!multiple()) {
    <mat-radio-group [(ngModel)]="projectionFormatIndex" (change)="updateFormat($event.value)" [disabled]="disabled()">
      <div>{{ i18n.get('selectFormat') }}:</div>
      @for (projection of availableProjections; track projection; let i = $index) {
        <mat-radio-button [value]="i">{{ projection.name }}</mat-radio-button>
      }
    </mat-radio-group>
  } @else {
    <div>
      <div>{{ i18n.get('selectFormat') }}:</div>
      @for (projection of availableProjections; track projection; let i = $index) {
        <mat-checkbox
          [checked]="projectionFormatIndexes().includes(i)"
          (change)="updateFormats(i, $event.checked)"
          [disabled]="disabled()"
          >{{ projection.name }}</mat-checkbox
        >
      }
    </div>
  }
  @if (showNumerical()) {
    <span class="separator">|</span>
    <mat-checkbox [checked]="numerical" (change)="updateNumerical($event.checked)" [disabled]="disabled()">{{
      i18n.get('numerical')
    }}</mat-checkbox>
  }
</div>
