<div class="flex-label">
  @if (labelEdit) {
    <mat-form-field appearance="outline" style="margin-bottom: -1.25em">
      <mat-label>{{ i18n.get('myName') }}</mat-label>
      <input type="text" matInput [ngModel]="session.getLabel()" (ngModelChange)="label$.next($event)" maxlength="40" />
    </mat-form-field>
  } @else {
    <span class="label-span">{{ session.getLabel() }}</span>
  }
  <button mat-icon-button color="basic" (click)="toggleEditLabel()">
    @if (!labelEdit) {
      <mat-icon>edit</mat-icon>
    } @else {
      <mat-icon>save</mat-icon>
    }
  </button>
</div>
<hr />
@if (!isWorkLocal && (isOnline | async)) {
  <div class="connections-container">
    <mat-slide-toggle
      color="primary"
      [ngModel]="showCurrentLocation$ | async"
      (change)="state.updateShowCurrentLocation($any($event).checked)"
    >
      <mat-label>{{ i18n.get('shareLocation') }}</mat-label>
    </mat-slide-toggle>
    <hr />
    <mat-label class="font-semibold">{{ i18n.get('online') }}</mat-label>
    <mat-list>
      @for (connection of connections$ | async; track connection) {
        <mat-list-item class="flex-container">
          <div class="flex-container-connection">
            <div class="flex-container-connection-label">
              <mat-icon mat-list-icon>person</mat-icon>
              <span>{{ connection.label }}</span>
            </div>
            @if (connection.currentLocation) {
              <button mat-icon-button color="primary" (click)="centerMap(connection.currentLocation)">
                <mat-icon mat-list-icon>person_pin_circle</mat-icon>
              </button>
            }
          </div>
        </mat-list-item>
      }
    </mat-list>
  </div>
}
@if (!(isOnline | async)) {
  <div class="offline-container">
    <mat-icon>cloud_off</mat-icon>
    <mat-label class="font-gray">{{ i18n.get('youAreOffline') }}</mat-label>
  </div>
}
@if (isWorkLocal) {
  <div class="local-container">
    <p>{{ i18n.get('localAvailabilityStepsIntro') }}</p>
      <mat-checkbox [checked]="useLocalBaseMap" [disabled]="useLocalBaseMap" (click)="changeToLocalMap()">{{
        i18n.get('useLocalBaseMap')
      }}</mat-checkbox>
      <mat-checkbox [checked]="downloadLocalBaseMap" [disabled]="downloadLocalBaseMap" (click)="downloadLocalMap()">{{
        i18n.get('downloadLocalBaseMap')
      }}</mat-checkbox>
      <mat-checkbox [checked]="hideUnavailableLayers" [disabled]="hideUnavailableLayers" (click)="hideUnavailable()">{{
        i18n.get('hideUnavailableLayers')
      }}</mat-checkbox>
      <mat-checkbox [checked]="downloadAvailableLayers" [disabled]="downloadAvailableLayers" (click)="downloadAvailable()">{{
        i18n.get('downloadAvailableLayers')
      }}</mat-checkbox>
      <mat-checkbox [checked]="haveSearchCapability" [disabled]="haveSearchCapability" (click)="showSearchInfo($event)">{{
        i18n.get('haveSearchCapability')
      }}</mat-checkbox>
  </div>
}
