<app-incident-select
  [values]="(incidents | async) || []"
  (valuesChange)="updateIncidents($event)"
  [disabled]="!hasWritePermission || isArchived"
></app-incident-select>

@if (isArchived) {
  <p class="archived">{{ i18n.get('viewArchivedOperation') }}</p>
}
<mat-divider></mat-divider>

<button mat-menu-item (click)="toggleHistory()">
  @if (!(zsMapStateService.observeIsHistoryMode() | async)) {
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('history')">history</mat-icon>
  } @else {
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('drawMode')">brush</mat-icon>
  }
  {{ (zsMapStateService.observeIsHistoryMode() | async) ? i18n.get('drawMode') : i18n.get('history') }}
</button>
<mat-divider></mat-divider>

<button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="languageOptions">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('language')">language</mat-icon>
  {{ i18n.get('language') }}
</button>
<button mat-menu-item class="tools-menu-item" (click)="print()">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('print')">local_printshop</mat-icon>
  {{ i18n.get('print') }}
</button>
<button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="protocolOptions">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocol')">list_alt</mat-icon>
  {{ i18n.get('protocol') }}
</button>
<button mat-menu-item class="tools-menu-item" (click)="personRecovery()">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('personRecoveryOverview')">sensor_occupied</mat-icon>
  {{ i18n.get('personRecoveryOverview') }}
</button>
<button mat-menu-item (click)="help()">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('help')">help</mat-icon>
  {{ i18n.get('help') }}
</button>
<mat-divider></mat-divider>
<div class="mat-menu-item-with-info">
  <button mat-menu-item (click)="zsMapStateService.toggleExpertView()">
    @if (!(zsMapStateService.observeIsExpertView() | async)) {
      <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('exportSession')">manage_accounts</mat-icon>
    }
    @if (zsMapStateService.observeIsExpertView() | async) {
      <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('defaultView')">person</mat-icon>
    }
    {{ (zsMapStateService.observeIsExpertView() | async) ? i18n.get('defaultView') : i18n.get('expertView') }}
  </button>
  <button mat-icon-button (click)="showExpertViewHelp()">
    <mat-icon>info</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
@if (session.observeHasWritePermission() | async) {
  <button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="shareOptions">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('share')">share</mat-icon>
    {{ i18n.get('share') }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item class="tools-menu-item" (click)="navigateEvents()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('navigateOperations')">logout</mat-icon>
    {{ i18n.get('navigateOperations') }}
  </button>
}
@if (!(session.observeHasWritePermission() | async)) {
  <button mat-menu-item class="tools-menu-item" (click)="session.logout('logout')">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('logout')">logout</mat-icon>
    {{ i18n.get('logout') }}
  </button>
}

<mat-menu #languageOptions="matMenu">
  @for (locale of locales; track locale) {
    <button mat-menu-item (click)="setLocale(locale)" [ngClass]="{ active: locale === session.getLocale() }">
      {{ i18n.get(locale) }}
    </button>
  }
</mat-menu>

<mat-menu #protocolOptions="matMenu">
  <button mat-menu-item class="tools-menu-item" (click)="protocolTable()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocolTable')">table_chart</mat-icon>
    {{ i18n.get('protocolTable') }}
  </button>
  <button mat-menu-item class="tools-menu-item" (click)="protocolExcelExport()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocolSaveAsExcel')">save</mat-icon>
    {{ i18n.get('protocolSaveAsExcel') }}
  </button>
</mat-menu>
<ng-template #projectionSelectionTemplate let-data>
  <div mat-dialog-content>
    <app-projection-selection
      [projectionFormatIndex]="data.projectionFormatIndex"
      [numerical]="data.numerical"
      (projectionChanged)="
        data.numerical = $event.numerical ?? data.numerical;
        data.projectionFormatIndex = $event.projectionFormatIndex ?? data.projectionFormatIndex
      "
    ></app-projection-selection>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button [mat-dialog-close]="null">{{ i18n.get('cancel') }}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>{{ i18n.get('ok') }}</button>
  </div>
</ng-template>

<mat-menu #shareOptions="matMenu">
  <button mat-menu-item (click)="generateShareLink(false, false)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateMultiUseShareLink')">share</mat-icon>
    {{ i18n.get('generateMultiUseShareLink') }}
  </button>
  <button mat-menu-item (click)="generateShareLink(true, false)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateMultiUseShareLink')">share</mat-icon>
    {{ i18n.get('generateMultiUseShareLink') }} (readonly)
  </button>
  <button mat-menu-item (click)="generateShareLink(false, true)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateSingleUseShareLink')">qr_code</mat-icon>
    {{ i18n.get('generateSingleUseShareLink') }}
  </button>
  <button mat-menu-item (click)="generateShareLink(true, true)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateSingleUseShareLink')">qr_code</mat-icon>
    {{ i18n.get('generateSingleUseShareLink') }} (readonly)
  </button>
  <button mat-menu-item (click)="showRevokeShareDialog()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('revokeAccess')">locked</mat-icon>
    {{ i18n.get('revokeAccess') }}
  </button>
</mat-menu>
